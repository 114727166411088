/* eslint-disable react/no-danger */
import React, {useContext} from 'react'
import {renderToStaticMarkup} from 'react-dom/server'

import normalizePath from '../utils/normalizePath'
import {isSSR} from '../constants'
import VersionContext from '../providers/VersionContext'
import isVersionIndex from '../utils/isVersionIndex'
import getIndexRelativeUrl from '../utils/getIndexRelativeUrl'
import siteConfig from '../docs/siteconfig.yaml'
import {SiteConfig} from '../types'
import getLoginUrl from '../utils/getLoginUrl'

const {endpoints = {}} = siteConfig as SiteConfig

interface MDXAnchorProps extends React.HTMLAttributes<HTMLAnchorElement> {
  href: string
}

export default function MDXAnchor({children, href, ...props}: MDXAnchorProps): JSX.Element {
  const {version} = useContext(VersionContext)

  if (isSSR) return <a {...props}>{children}</a>

  let relativizedHref = href
  if (href === endpoints.login) {
    relativizedHref = getLoginUrl()
  } else if (href.startsWith('/')) {
    relativizedHref = getIndexRelativeUrl(href)
  } else if (href.startsWith('.') && !(isVersionIndex(version) || /\.\.\/?$/.test(href))) {
    // leave relative links on the index page unchanged
    // leave relative links to ancestor directories unchanged
    // rewrite relative links to one directory up since we are forcing trailing slashes for relativize plugin
    relativizedHref = normalizePath(
      href.startsWith('./') ? href.replace(/^\.\//, '../') : `../${href}`
    )
  }

  // gastby-plugin-mdx renders <a> tags with GatsbyLink even when as a child of this custom component
  // have to `dangerouslySetInnerHTML` instead to override this
  // we need a native <a> for two reasons:
  //   1) avoid <GatsbyLink> transforming the url again after we've manually adjusted it
  //   2) a path that is reverse proxied can't be handled with client-side routing
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: renderToStaticMarkup(
          <a href={relativizedHref} {...props}>
            {children}
          </a>
        ),
      }}
    />
  )
}
