import {isSSR} from '../constants'
import {Version} from '../types'

/**
 * Tests if the current browser location is the index page for the given version
 */
export default function isVersionIndex(version?: Version): boolean {
  if (isSSR) return false
  if (!version) return window.location.pathname === `${window.__RELATIVIZE_PREFIX__}/`
  if (version.latest && window.location.pathname === '/') return true

  return (
    window.location.pathname ===
    `${window.__RELATIVIZE_PREFIX__}/${version.latest ? '' : `${version.identifier}/`}`
  )
}
