import React from 'react'
import {MDXProvider as InnerMDXProvider, Components, MDXProviderProps} from '@mdx-js/react'

import CodeBlock from '../components/CodeBlock'
import Heading from '../components/Heading'
import MDXAnchor from '../components/MDXAnchor'
import MDXImage from '../components/MDXImage'
import MDXWrapper from '../components/MDXWrapper'
import Blocks from '../components/Block/Blocks'
import BlockPane from '../components/Block/BlockPane'

// fix type declaration in gatsby-plugin-mdx which does not match api
interface MDXProviderComponents extends Components {
  wrapper?: React.ComponentType<{children: React.ReactNode}>
}

const defaultComponents: MDXProviderComponents = {
  wrapper: MDXWrapper,
  pre: CodeBlock as React.FunctionComponent,
  a: MDXAnchor,
  img: MDXImage,
  h1: Heading.H1,
  h2: Heading.H2,
  h3: Heading.H3,
  h4: Heading.H4,
  h5: Heading.H5,
  h6: Heading.H6,
  Blocks,
  BlockPane,
}

export default function MDXProvider({
  children,
  components,
  ...props
}: MDXProviderProps): JSX.Element {
  return (
    <InnerMDXProvider components={{...defaultComponents, ...components}} {...props}>
      {children}
    </InnerMDXProvider>
  )
}
