import siteConfig from '../docs/siteconfig.yaml'
import {SiteConfig} from '../types'

import getIndexRelativeUrl from './getIndexRelativeUrl'

export default function getLoginUrl(): string {
  const loginEndpoint = getIndexRelativeUrl((siteConfig as SiteConfig).endpoints?.login || '/login')
  const returnTo = encodeURIComponent(window.location.href.replace(window.location.search, ''))
  return `${loginEndpoint}?returnTo=${returnTo}`
}
