import * as React from 'react'
import Highlight, {defaultProps, Language} from 'prism-react-renderer'
import rangeParser from 'parse-numeric-range'
import {css} from '@emotion/react'
import {useMemo} from 'react'

import styles from '../styles'

import CopyableValue from './CopyableValue'

const codeBlockTitleCss = css`
  color: #000;
  background: #f5f5f5;
  font-size: 14px;
  padding: 0.5em 1em 0.5em;
  border-bottom: 1px solid #959595;
`
const lineNumCss = css`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  padding-left: 1em;
  user-select: none;
  opacity: 0.5;
`

const lineContentCss = css`
  display: table-cell;
  width: 100%;
  padding-right: 1em;
  padding-left: 1em;
`

const preCss = css`
  text-align: left;
  padding: 0;
  padding-top: 1em;
  padding-bottom: 1em;
  overflow: auto;
`

const blockCss = css`
  position: relative;
  &:hover > .copyBtn {
    display: block;
  }
`
const copyBtnCss = css`
  display: none;
  position: absolute;
  right: 0;
  padding: 0.5em 1em 0 0;
`

const highlightedLineCss = css`
  background: rgba(0, 0, 0, 0.16);
`

interface ChildrenProps extends React.HTMLProps<HTMLPreElement> {
  highlight: string
  showLineNumbers: string
  metastring: string
  children: string
}

interface CodeBlockProps {
  children: React.ReactElement<ChildrenProps>
}

const codeBlockTitleRegex = /title=(?<quote>["'])(?<title>.*?)\1/

function parseCodeBlockTitle(metastring?: string): string {
  return metastring?.match(codeBlockTitleRegex)?.groups?.title ?? ''
}

export default function CodeBlock({children}: CodeBlockProps): JSX.Element {
  const cls = children.props.className || ''
  const langMatch = cls.match(/language-(?<lang>.*)/)
  const language: Language = langMatch?.groups?.lang || ''
  const title = parseCodeBlockTitle(children.props.metastring) || null
  const showLineNumbers = !!children.props.showLineNumbers

  const isLineHighlighted = useMemo(() => {
    const highlightedLines =
      rangeParser(children.props.highlight?.replace(/^"(.*)"$/, '$1').replace(/L/g, '') || '') || []
    return (index: number) => highlightedLines.includes(index + 1)
  }, [children.props.highlight])

  return (
    <div>
      {title && <div css={codeBlockTitleCss}>{title}</div>}
      <div css={blockCss}>
        <div css={copyBtnCss} className="copyBtn">
          <CopyableValue value={children.props.children} hidePreview />
        </div>

        <Highlight
          Prism={defaultProps.Prism}
          theme={styles.code.theme}
          code={children.props.children}
          language={language}
        >
          {({className, style, tokens, getLineProps, getTokenProps}) => (
            <pre css={preCss} className={className} style={style}>
              {tokens.map((line, i) => {
                // don't render last line if it's empty
                if (
                  i === tokens.length - 1 &&
                  (!line.length || (line.length === 1 && line[0].empty))
                )
                  return null
                return (
                  <div
                    data-testid={isLineHighlighted(i) ? 'codeblock-highlighted-line' : ''}
                    css={isLineHighlighted(i) && highlightedLineCss}
                    {...getLineProps({line, key: i})}
                  >
                    {showLineNumbers && (
                      <span css={lineNumCss} data-testid="codeblock-line-num">
                        {i + 1}
                      </span>
                    )}
                    <div css={lineContentCss}>
                      {line.map((token, key) => (
                        <span {...getTokenProps({token, key})} />
                      ))}
                    </div>
                  </div>
                )
              })}
            </pre>
          )}
        </Highlight>
      </div>
    </div>
  )
}
