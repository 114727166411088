/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import 'whatwg-fetch'
import 'proxy-polyfill'
import 'url-polyfill'

import {Buffer} from 'buffer'

import React from 'react'

import UserProvider from './src/providers/UserProvider'
import MDXProvider from './src/providers/MDXProvider'
import HeaderHeightProvider from './src/providers/HeaderHeightProvider'
import {IsMountedProvider} from './src/hooks/useIsMounted'

export const wrapRootElement = ({element}) => (
  <UserProvider>
    <IsMountedProvider>
      <MDXProvider>
        <HeaderHeightProvider>{element}</HeaderHeightProvider>
      </MDXProvider>
    </IsMountedProvider>
  </UserProvider>
)

export const onClientEntry = () => {
  // polyfill needed by Redoc since webpack >=5 no longer does this automatically for node builtins
  window.Buffer = Buffer
}
