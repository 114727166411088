import React, {ReactElement} from 'react'

type Props = {
  title: string
  children: ReactElement | ReactElement[]
}

const BlockPane = ({children}: Props): JSX.Element => <div role="tabpanel">{children}</div>

export default BlockPane
