import React, {useCallback} from 'react'
import {css} from '@emotion/react'

const buttonCss = css`
  background-color: #fff;
  border: none;
  text-align: center;
  text-decoration: none;
  padding: 0.5em;
  margin: 0.25em;
  width: 100%;
  border-bottom: 2px solid transparent;

  &:hover {
    background-color: #f5f5f5;
    padding: 0.5em;
    cursor: pointer;
    transition: 0.1s linear;
  }

  @media only screen and (max-width: 450px) {
    margin: 0;
    text-align: left;
  }
`

const activeButtonCss = css`
  color: #1a819a;
  padding: 0.5em;
  cursor: pointer;
  transition: 0.1s linear;
  border-bottom: 2px solid #1a819a;
`

export type Props = {
  label: string
  index: number
  setSelectedBlock: (index: number) => void
  isActive?: boolean
}

const BlockTitle = (props: Props): JSX.Element => {
  const {label, index, setSelectedBlock, isActive} = props

  const handleOnClick = useCallback(() => setSelectedBlock(index), [setSelectedBlock, index])

  return (
    <li role="tab">
      <button
        css={[buttonCss, isActive && activeButtonCss]}
        type="button"
        role="tab"
        onClick={handleOnClick}
      >
        {label}
      </button>
    </li>
  )
}

export default BlockTitle
