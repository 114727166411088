import React, {useReducer, useEffect, useCallback} from 'react'

import siteConfig from '../docs/siteconfig.yaml'
import {SiteConfig} from '../types'
import assertNever from '../utils/assertNever'
import getIndexRelativeUrl from '../utils/getIndexRelativeUrl'
import fetchResource from '../utils/fetchResource'

import UserContext, {User, UserContextInterface} from './UserContext'

const {endpoints} = siteConfig as SiteConfig

type UserAction = {type: 'logout'} | {type: 'updateUser'; user: User}

interface UserState {
  user?: User
  _state: UserContextInterface['_state']
}

function userReducer(
  state: UserState,
  action: UserAction
): {user?: User; _state: UserContextInterface['_state']} {
  switch (action.type) {
    case 'updateUser':
      return {_state: 'known', user: action.user}
    case 'logout':
      return {_state: 'known'}
    default:
      return assertNever(action, 'Unknown action type')
  }
}

function getUser(dispatch: (action: UserAction) => void): void {
  if (!endpoints?.user) return
  fetchResource<User>(getIndexRelativeUrl(endpoints.user))
    .then((user) => {
      dispatch({type: 'updateUser', user})
    })
    .catch(() => dispatch({type: 'logout'}))
}

function triggerUpstreamLogout(dispatch: (action: UserAction) => void): void {
  if (!endpoints?.logout) return
  // user should already be unset, but reset the state on an error just in case
  fetchResource<never>(getIndexRelativeUrl(endpoints.logout), {method: 'POST'}).catch((error) => {
    // eslint-disable-next-line no-console
    console.error(`Error logging out ${error}`)
    dispatch({type: 'logout'})
  })
}

export default function UserProvider({children}: React.PropsWithChildren<null>): JSX.Element {
  const [{user, _state}, dispatch] = useReducer(userReducer, {_state: 'unknown'})

  useEffect(() => {
    // get the user on first load
    if (endpoints) {
      getUser(dispatch)
    }
  }, [])

  const checkLoginStatus = useCallback(() => {
    if (endpoints) {
      getUser(dispatch)
    }
  }, [])

  const logout = useCallback(() => {
    dispatch({type: 'logout'})
    triggerUpstreamLogout(dispatch)
  }, [])

  return (
    <UserContext.Provider
      value={{
        user,
        loginUrl: getIndexRelativeUrl(endpoints?.login),
        checkLoginStatus,
        logout,
        _state,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
