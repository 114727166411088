// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-account-page-tsx": () => import("./../../../src/layouts/AccountPage.tsx" /* webpackChunkName: "component---src-layouts-account-page-tsx" */),
  "component---src-layouts-markdown-tsx": () => import("./../../../src/layouts/Markdown.tsx" /* webpackChunkName: "component---src-layouts-markdown-tsx" */),
  "component---src-layouts-redoc-tsx": () => import("./../../../src/layouts/Redoc.tsx" /* webpackChunkName: "component---src-layouts-redoc-tsx" */),
  "component---src-layouts-section-overview-tsx": () => import("./../../../src/layouts/SectionOverview.tsx" /* webpackChunkName: "component---src-layouts-section-overview-tsx" */),
  "component---src-layouts-support-tsx": () => import("./../../../src/layouts/Support.tsx" /* webpackChunkName: "component---src-layouts-support-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

