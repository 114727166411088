import {PrismTheme} from 'prism-react-renderer'

const colors = {
  plain: {
    color: '#000',
    backgroundColor: '#f5f5f5',
  },
  comment: '#959595',
  string: '#517833',
  operator: '#393A34',
  symbol: '#36acaa',
  keyword: '#00a4db',
  function: '#732599',
  functionVariable: '#6f42c1',
  tag: '#00009f',
  diff: {
    inserted: {
      color: '#22863a',
      backgroundColor: '#f0fff4',
    },
    deleted: {
      color: '#b31d28',
      backgroundColor: '#ffeef0',
    },
  },
}

// Forked from https://github.com/FormidableLabs/prism-react-renderer/blob/master/src/themes/github.js
const theme: PrismTheme = {
  plain: {
    color: colors.plain.color,
    backgroundColor: colors.plain.backgroundColor,
    fontSize: 14,
  },
  styles: [
    {
      types: ['comment', 'prolog', 'doctype', 'cdata'],
      style: {
        color: colors.comment,
        fontStyle: 'italic',
      },
    },
    {
      types: ['namespace'],
      style: {
        opacity: 0.7,
      },
    },
    {
      types: ['string', 'attr-value'],
      style: {
        color: colors.string,
      },
    },
    {
      types: ['punctuation', 'operator'],
      style: {
        color: colors.operator,
      },
    },
    {
      types: ['inserted'],
      style: {
        color: colors.diff.inserted.color,
        backgroundColor: colors.diff.inserted.backgroundColor,
      },
    },
    {
      types: ['deleted'],
      style: {
        color: colors.diff.deleted.color,
        backgroundColor: colors.diff.deleted.backgroundColor,
      },
    },
    {
      types: [
        'entity',
        'url',
        'symbol',
        'number',
        'boolean',
        'variable',
        'constant',
        'property',
        'regex',
      ],
      style: {
        color: colors.symbol,
      },
    },
    {
      types: ['atrule', 'keyword', 'attr-name', 'selector'],
      style: {
        color: colors.keyword,
      },
    },
    {
      types: ['function', 'tag'],
      style: {
        color: colors.function,
      },
    },
    {
      types: ['function-variable'],
      style: {
        color: colors.functionVariable,
      },
    },
    {
      types: ['tag', 'selector', 'keyword'],
      style: {
        color: colors.tag,
      },
    },
  ],
}

export default {theme, colors}
