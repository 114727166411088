import {createContext} from 'react'

import {Version} from '../types'

interface VersionContext {
  versions: Version[]
  version?: Version
}

export default createContext<VersionContext>({versions: []})
