import {css} from '@emotion/react'

import code from './code'

export const colors = {
  kenshoBlue: '#1a819a',
}

const baseInputCss = css`
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 4px 8px;
  width: 370px;
  max-width: 100%;
  margin-top: 4px;
  display: block;
  font-weight: normal;

  &:invalid {
    border-color: red;
  }
`

const form = {
  h1: css`
    font-size: 24px;
    color: rgba(0, 0, 0, 0.87);
  `,

  h2: css`
    font-size: 24px;
    color: rgba(0, 0, 0, 0.87);

    margin-top: 45px;
  `,

  label: css`
    display: block;
    font-weight: bold;
    line-height: 20px;
    margin: 0;
    margin-bottom: 10px;
  `,

  input: css`
    ${baseInputCss}
    height: 36px;
  `,

  textarea: css`
    ${baseInputCss}
    min-height: 62px;
  `,

  buttonPrimary: css`
    background: ${colors.kenshoBlue};
    color: white;
    border-radius: 3px;
    border: none;
    padding: 4px 10px;

    &:disabled {
      background: #ddd;
    }
  `,

  errorText: css`
    color: red;
    font-weight: bold;
  `,
}

const header = {
  link: css`
    color: rgba(255, 255, 255, 0.87);
    text-decoration: none;
    margin: 0 12px;
  `,
}

const text = {
  fontFamily: 'proxima-nova, "Proxima Nova", sans-serif',
}

export default {form, text, header, code}
