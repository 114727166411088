import React, {useState} from 'react'

import {headerHeight, secondaryHeaderHeight} from '../constants'

import HeaderHeightContext from './HeaderHeightContext'

interface HeaderHeightProviderProps {
  fullHeaderHeight: number
  setFullHeaderHeight: (newHeight: number) => void
}

export default function HeaderHeightProvider({
  children,
}: React.PropsWithChildren<HeaderHeightProviderProps>): JSX.Element {
  const [fullHeaderHeight, setFullHeaderHeight] = useState(headerHeight + secondaryHeaderHeight)

  return (
    <HeaderHeightContext.Provider
      value={{
        fullHeaderHeight,
        setFullHeaderHeight,
      }}
    >
      {children}
    </HeaderHeightContext.Provider>
  )
}
