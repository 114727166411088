import path from 'path-browserify'

import {isSSR} from '../constants'

/**
 * Calculates the url for an endpoint relative to the index of the documentation site
 */
export default function getIndexRelativeUrl(urlPath = ''): string {
  if (isSSR) return urlPath
  // if this is not a relative url (https://*) return unmodified
  if (/^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(urlPath)) return urlPath
  return path.join(window.__RELATIVIZE_PREFIX__ || '/', urlPath)
}
