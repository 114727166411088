import React, {ReactElement, useState, Children} from 'react'
import {css} from '@emotion/react'

import BlockTitle, {Props as BlockTitleProps} from './BlockTitle'

const containerCss = css`
  display: flex;
  flex-direction: column;
`
const tabItemsCss = css`
  display: flex;
  list-style-type: none;
  margin: 0;
  flex-wrap: wrap;
  align-content: flex-start;

  @media only screen and (max-width: 450px) {
    display: none;
  }
`

const dropdownCss = css`
  display: none;

  @media only screen and (max-width: 450px) {
    display: flex;
    background-color: #fff;
    border: none;
    padding: 0.5em;
    margin: 0.25em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  }
`

type Props = {
  children: ReactElement<BlockTitleProps>[]
}

const Blocks = (props: Props): JSX.Element => {
  const {children} = props
  const [selectedBlockIndex, setSelectedBlockIndex] = useState<number>(0)

  return (
    <div css={containerCss}>
      <select
        css={dropdownCss}
        value={selectedBlockIndex}
        onChange={(e) => setSelectedBlockIndex(parseInt(e.target.value, 10))}
      >
        {Children.map(children, (item, index) => (
          <option key={item.props.label} value={index}>
            {item.props.label}
          </option>
        ))}
      </select>
      <ul css={tabItemsCss}>
        {Children.map(children, (item, index) => (
          <BlockTitle
            key={item.props.label}
            label={item.props.label}
            index={index}
            isActive={index === selectedBlockIndex}
            setSelectedBlock={setSelectedBlockIndex}
          />
        ))}
      </ul>
      {children.length > 0 ? children[selectedBlockIndex] : children}
    </div>
  )
}

export default Blocks
