import camelCaseDeep from './camelCaseDeep'

/**
 * Fetch a JSON resource from the specified API endpoint
 */
export default function fetchResource<T>(
  apiEndpoint: string,
  params?: RequestInit,
  timeout?: number
): Promise<T> {
  return new Promise((resolve, reject) => {
    fetch(apiEndpoint, params)
      .then((response) => {
        if (response.ok) return response.json()
        throw response
      })
      .then((json) => camelCaseDeep(json) as T)
      .then(resolve, reject)

    if (timeout && timeout > 0) {
      setTimeout(reject, timeout, new Error('Request timed out'))
    }
  })
}
