import {createContext} from 'react'

interface HeaderHeightContext {
  fullHeaderHeight: number
  setFullHeaderHeight: (newHeight: number) => void
}

export default createContext<HeaderHeightContext>({
  fullHeaderHeight: 0,
  setFullHeaderHeight: () => null,
})
