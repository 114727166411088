import React from 'react'
import {css} from '@emotion/react'

import {isSSR} from '../constants'

const imgCss = css`
  margin-bottom: 0;
`

export default function MDXImage({
  src = '',
  alt,
  loading = 'lazy',
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement>): JSX.Element {
  let relativizedSrc = src
  // images are located at the root of the site so need to increase depth by 1 for index page with trailing slash
  if (!isSSR && window.location.pathname === `${window.__RELATIVIZE_PREFIX__}/`)
    relativizedSrc = relativizedSrc.replace(/^\.\.\//, './')

  return <img css={imgCss} src={relativizedSrc} alt={alt} loading={loading} {...props} />
}
