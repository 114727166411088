import {createContext} from 'react'

export interface User {
  email?: string
  token?: string
  refreshToken?: string
}

export interface UserContextInterface {
  user?: User
  loginUrl?: string
  checkLoginStatus: () => void
  logout: () => void
  _state: 'known' | 'unknown'
}

export default createContext<UserContextInterface>({
  checkLoginStatus: () => null,
  logout: () => null,
  _state: 'unknown',
})
