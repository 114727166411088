import React from 'react'
import {css} from '@emotion/react'

const wrapperCss = css`
  font-size: 16px;

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.3em;
  }

  h3 {
    font-size: 1.1em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.9em;
  }

  h6 {
    font-size: 0.7em;
  }

  a:hover {
    color: #007697;
  }

  .mdx-heading {
    position: relative;
  }

  a.anchor {
    position: absolute;
    left: -20px;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  .mdx-heading:hover a.anchor {
    opacity: 0.35;

    &:hover {
      opacity: 1;
    }
  }

  code {
    margin: 0 0.2em;
    padding: 0.2em 0.3em;
    font-size: 14px;
  }

  pre {
    font-size: 16px;
  }
`

export default function MDXWrapper({
  children,
}: React.PropsWithChildren<Record<string, unknown>>): JSX.Element {
  return <div css={wrapperCss}>{children}</div>
}
