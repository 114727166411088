export const isSSR = typeof window === 'undefined'

export const articleMaxWidth = 900
export const headerHeight = 50
export const secondaryHeaderHeight = 50
export const fadingHeaderSpeed = '0.2s'

export const COMPANY_NAME = 'Kensho Technologies, LLC.'
export const CURRENT_YEAR = new Date().getFullYear()

export const EMAILS = {
  INFO: 'info@kensho.com',
  SECURITY: 'security-reports@kensho.com',
  SUPPORT: 'support@kensho.com',
  PRIVACY: 'privacy@kensho.com',
}

export const SOCIAL_URLS = {
  LINKEDIN: 'https://linkedin.com/company/kensho-technologies',
  TWITTER: 'https://twitter.com/kensho',
}

export const KENSHO_PUBLIC = {
  HOME: 'https://kensho.com',
  CONTACT: 'https://kensho.com/contact',
  CONTACT_FORM: 'https://kensho.com/contact/solutions?product=',
  PRIVACY_POLICY: 'https://kensho.com/privacy',
  TERMS_OF_USE: 'https://kensho.com/terms-of-use',
  TERMS_OF_SERVICE: 'https://kensho.com/terms-of-service',
}

export const MARKETPLACE_IDP = '0oa233h7mov7U7gD14x7'
