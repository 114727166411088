import React from 'react'

type HeadingProps = React.HTMLAttributes<HTMLHeadingElement>

function H1({children, className = '', ...props}: HeadingProps): JSX.Element {
  return (
    <h1 {...props} className={`${className} mdx-heading`}>
      {children}
    </h1>
  )
}

function H2({children, className = '', ...props}: HeadingProps): JSX.Element {
  return (
    <h2 {...props} className={`${className} mdx-heading`}>
      {children}
    </h2>
  )
}

function H3({children, className = '', ...props}: HeadingProps): JSX.Element {
  return (
    <h3 {...props} className={`${className} mdx-heading`}>
      {children}
    </h3>
  )
}

function H4({children, className = '', ...props}: HeadingProps): JSX.Element {
  return (
    <h4 {...props} className={`${className} mdx-heading`}>
      {children}
    </h4>
  )
}

function H5({children, className = '', ...props}: HeadingProps): JSX.Element {
  return (
    <h5 {...props} className={`${className} mdx-heading`}>
      {children}
    </h5>
  )
}

function H6({children, className = '', ...props}: HeadingProps): JSX.Element {
  return (
    <h6 {...props} className={`${className} mdx-heading`}>
      {children}
    </h6>
  )
}

export default {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
}
